<template>
    <div>
        <swiper class="nick_swiper" ref="swiper" :options="swiperOption" @click="onClick"
                @slideChange="slideChange">
            <swiper-slide class="nick_swiper_slide" v-for="(data,index) in itemData" :key="`itemSwiper${index}`">
                <div class="nick_card"
                     :id="`userItem${data.i_sku}`"
                     :style="`background-image: url(${returnThumbnail(data)});`"></div>
            </swiper-slide>
        </swiper>
        <div class="back_btn"></div>
        <div class="next_btn"></div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListUserItemLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {
        Swiper,
        SwiperSlide,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 24,
                autoWidth: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                    clickable: true
                },
                navigation: {
                    nextEl: '.next_btn',
                    prevEl: '.back_btn',
                },
            },
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return this.setFitWidthImg(data.ItemFile.Item, 285)
            }
        },
        moveDetail(data) {
            if(data.i_idx ===  Number(this.$route.params.idx)){
                return false
            }
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.replace(`/premiummarket/${data}?tab=item`);
                this.$router.go();
            } else {
                this.$router.replace(`/openmarket/${data}?tab=item`);
                this.$router.go();
            }
        },

        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementId = element.id;
            if (util.isEmpty(elementId) || elementId.indexOf('userItem')) {
                return false;
            }
            let elementIdx = elementId.replace('userItem', "");
            console.log(elementIdx)
            this.moveDetail(elementIdx);
        },
    },
    watch: {
    },
}
</script>

<style scoped>

</style>
